/*--------------------------
    variables
---------------------------*/
//fonts
$primary-font: 'GTWalsheim-Light', sans-serif;
$secondary-font: 'GTWalsheim-Medium', sans-serif;

//colors
$primary-color: #C1E1FA;
$secondary-color: #F1D3FD;
$black: #000000;
$gray: #ACACAC;
$white: #FFFFFF;


/*--------------------------
    sidebar
---------------------------*/
.sidebar {
    grid-column: 1/2;
    padding: 3rem;
    background-color: $black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 31.25em) { 
        flex-direction: row;
        padding: 2rem 3rem;
        justify-content: space-between;
    }

    img {
        margin-top: 1.5rem;
        @media only screen and (max-width: 54.125em) { 
            width: 7rem;
        }
        @media only screen and (max-width: 31.25em) { 
            margin-top: 0;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: calc(100vh/4);
        @media only screen and (max-width: 31.25em) { 
            flex-direction: row;
            margin-top: 0;
        }

        li {
            list-style: none;
            &:not(:last-child) {
                margin-bottom: 5rem;
                @media only screen and (max-width: 31.25em) { 
                    margin-bottom: 0;
                    margin-right: 2rem;
                }
            }
            a {
                background-image: linear-gradient(90deg, $primary-color, $secondary-color);
                padding: 1.3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                border: 1px solid transparent;

                @media only screen and (max-width: 31.25em) { 
                    padding: 1rem;
                }


                &:hover {
                    background-image: none;
                    border: 1px solid $white;

                    svg {
                        fill: $white;
                        stroke: $white;
                    }
                }
                svg {
                    stroke: $black;
                    @media only screen and (max-width: 54.125em) { 
                        width: 2rem;
                        height: 2rem;
                    }
                    @media only screen and (max-width: 31.25em) { 
                        width: 1.7rem;
                        height: 1.7rem;
                    }
                }
            }
        }
    }
}



/*--------------------------
    content
---------------------------*/
.content {
    grid-column: 2/-1;
    padding: 3rem;
    background-image: url('../../assets/png/bg.png');
    background-size: cover;
    color: white;

    form {
        display: flex;
        align-items: center;
        margin-bottom: 8rem;
        @media only screen and (max-width: 31.25em) { 
            justify-content: center;
        }

        input {
            background-color: transparent;
            border: none;
            border-radius: 50px;
            border: 1px solid $gray;
            outline: none;
            color: white;
            padding: 1.5rem 1.5rem 1.5rem 4rem;
            width: 30rem;
            font-size: 1.5rem;
            margin-left: -2rem;

            &::placeholder {
                font-family: inherit;
                font-size: 1.5rem;
            }
        }

        svg {
            margin-right: -1rem;
        }
    }

    .profileWrapper {
        margin-bottom: 6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 85%;
        padding-bottom: 5rem;
        border-bottom: 1px solid $gray;

        @media only screen and (max-width: 54.125em) { 
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &__profile, &__bio {
                margin-bottom: 4rem;
                img {
                    width: 9rem !important;
                }
            }
        }

        &__profile {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                border: 2px solid $white;
                border-radius: 20px;
                margin-right: 2rem;
                width: 12rem;
            }
            div {
                h1 {
                    font-family: $secondary-font;
                    font-weight: 200;
                    letter-spacing: -1px;
                    font-size: 3.25rem;
                }
                p {

                }
            }
        }

        &__bio {
            max-width: 25rem;
            margin-right: -6rem;
            @media only screen and (max-width: 31.25em) { 
                text-align: center;
                margin-right: 0rem;
            }
            svg {
                margin-bottom: -1.5rem;
            }
            p {
                line-height: 1.5em;
                margin-top: -1rem;
            }
        }

        &__info {
            display: flex;
            align-items: center;
            justify-content: center;
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 2rem;
                &:not(:last-child) {
                    border-right: 1px solid $gray;
                }
                h5 {
                    font-size: 1.6rem;
                    margin-bottom: -1rem;
                }
                p {
                    font-size: 1.1rem;
                }
            }
        }
    }

    .repoWrapper {
        font-size: 85%;
        h1 {
            font-size: 2rem;
        }

        .repoContent {
            margin-top: 2.5rem;
            display: grid;grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
            grid-row-gap: 3rem;
            .repo {
                padding: 2rem;
                background-color: $black;
                border-radius: 30px;
                text-decoration: none;
                color: white;
                &:not(:last-child) {
                    margin-right: 3rem;
                }
                &__info {   
                    float: right;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    li {
                        background-image: linear-gradient(90deg, $primary-color, $secondary-color);
                        border-radius: 10px;
                        padding: 0 1.2rem;
                        font-size: 1rem;
                        color: $black;
                        font-weight: 700;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &:not(:last-child) {
                            margin-right: 1rem;
                        }
                        list-style: none;
                        svg {
                            margin-right: .3rem;
                        }
                    }
                }

                &__title {
                    margin-top: 7rem;
                    font-size: 1.6rem;
                }

                &__details {
                    line-height: 1.5em;
                    font-size: 1.3rem;
                    margin-top: 1rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 30rem;
                }
            }
        }
    }
}

